/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/htmx.org@1.9.10/dist/htmx.min.js
 * - /npm/htmx.org@1.9.10/dist/ext/alpine-morph.js
 * - /npm/@alpinejs/morph@3.13.6/dist/cdn.min.js
 * - /npm/@alpinejs/collapse@3.13.6/dist/cdn.min.js
 * - /npm/@alpinejs/persist@3.13.6/dist/cdn.min.js
 * - /npm/alpinejs@3.13.6/dist/cdn.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
